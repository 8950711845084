<template>
    <button type="button" class="flex gap-3 items-center px-4 py-3 rounded text-sm w-full text-left" :class="classes" :disabled>
        <Icon v-if="icon" :name="icon" :fill="iconFill" :size="20" class="shrink-0 -ml-1" />
        <slot>{{ label }}</slot>
    </button>
</template>

<script setup>
const props = defineProps({
    icon: {
        type: String,
    },

    iconFill: {
        type: Boolean,
        default: false,
    },

    danger: {
        type: Boolean,
    },

    active: {
        type: Boolean,
    },

    unavailable: {
        type: Boolean,
    },

    label: {
        type: String,
        default: '',
    },

    disabled: {
        type: [Boolean, Number, String],
        default: false,
    },
});

const classes = computed(() => {
    return {
        'text-sky-950 bg-sky-100/70': props.active,
        'hover:text-sky-950 hover:bg-sky-100/70': !props.danger && !props.disabled,
        'hover:bg-red-700/10 text-red-600': props.danger,
        'text-gray-500 hover:bg-gray-50 hover:text-gray-500': props.unavailable,
        'text-gray-500 cursor-not-allowed': props.disabled,
    };
});
</script>
